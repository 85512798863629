<template>
  <div>
    <div class="pane">
      <div class="title">通讯录</div>
      <div class="remarks">通讯录页面</div>
    </div>
    <a-card class="container">
      <a-form-model layout="inline">
        <a-form-model-item>
          <a-input v-model="form.name" placeholder="姓名"></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="form.deptUniqueName" placeholder="部门"></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="form.mobile" placeholder="手机号"></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click="reset">重置</a-button>
        </a-form-model-item>
      </a-form-model>

      <Padding />

      <a-table
        bordered
        :data-source="filteredList"
        :loading="loading"
        :pagination="{
          total: filteredList.length,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column title="姓名" data-index="name"></a-table-column>
        <a-table-column
          title="部门"
          data-index="deptUniqueName"
        ></a-table-column>
        <a-table-column title="手机" data-index="mobile"></a-table-column>
        <a-table-column title="邮箱" data-index="email"></a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { fetchEmployeeList } from "@/api/project";
export default {
  mixins: [watermark],

  data() {
    return {
      form: {},
      loading: false,
      list: [],
    };
  },

  computed: {
    filteredList() {
      let arr = [];
      if (this.form.name) {
        arr = this.list.filter(
          (item) => item.name && item.name.indexOf(this.form.name) > -1
        );
      }
      if (this.form.deptUniqueName) {
        arr = [
          ...arr,
          ...this.list.filter(
            (item) =>
              item.deptUniqueName &&
              item.deptUniqueName.indexOf(this.form.deptUniqueName) > -1
          ),
        ];
      }
      if (this.form.mobile) {
        arr = [
          ...arr,
          ...this.list.filter(
            (item) => item.mobile && item.mobile.indexOf(this.form.mobile) > -1
          ),
        ];
      }

      if (this.form.name || this.form.deptUniqueName || this.form.mobile) {
        return arr;
      } else {
        return this.list;
      }
    },
  },

  mounted() {
    const { query } = this.$route;
    const { name } = query || {};
    this.form = {
      name,
    };

    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      fetchEmployeeList({})
        .then((res) => {
          if (Array.isArray(res)) {
            this.list = Object.freeze(res);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    reset() {
      this.form = {};
    },
  },
};
</script>

<style lang="less" scoped>
.pane {
  background-color: #fff;
  padding: 16px 32px;
  .title {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.85);
  }
  .remarks {
    margin-top: 8px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.45);
  }
}
</style>